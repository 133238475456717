<template>
  <div class="bought">
    <van-nav-bar title="选择对象" left-arrow @click-left="$router.go(-1)" />
    <div class="bought-head">
    </div>
    <div class="bought-cont">
      <div v-for="(item, index) in tabData[tabCur]" :key="index" class="bought-cont-item"
        @click="checkItem(item,tabCur)">
        <div class="myPhoto">
          <img class="bought-cont-img" :src="item.img" alt="">
        </div>
        <div class="bought-cont-txtBox">
          <img v-if="item.unread" class="bought-cont-txtBox-tip" src="../../assets/img/callMe.png" alt="">
          {{item.name == '' ? '匿名用户' : item.name}}
          </p>
          <p>{{item.conteng}}</p>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import {
    Toast
  } from "vant";
  import Cookies from 'js-cookie';
  import wx from 'weixin-js-sdk';
  export default {
    data() {
      return {
        tabCur: 0,
        searchUser: '',
        showUser: true,
        tab: [{
          name: '聊天'
        }],
        userBtn: 0,
        userRow: {
          0: [],
          1: [{
            //img: require('../../assets/img/shuaige.png')
          }, ]
        },
        userList1: [],
        tabData: {
          0: [

          ],
          1: [{
              kh: 'service', //这名字就是卡号
              name: '客服',
              type: 'new',
              conteng: '如果有任何问题，您可以咨询我',
              img: 'https://mall.5izg.cn/upload/202106/10/202106102340304188.png',
            },
            {
              kh: '5556', //这名字就是卡号
              name: '客服02',
              type: 'new',
              conteng: '如果有任何问题，您可以咨询我',
              img: require('../../assets/img/shaofu.jpg'),
            },
          ]
        },
        languageLib: {
          lang_1: {
            title: 'chating',
            recommendedUser: 'recommended user',
            userRefresh: 'user refresh',
            chating: 'chating',
            email: 'EMAIL',
            customerService: 'Customer Service'
          },
          lang_2: {
            title: '聊天',
            recommendedUser: '推荐用户',
            userRefresh: '用户刷新',
            chating: '聊天',
            email: '客服',
            customerService: '客服'
          },
          lang_3: {
            title: '聊天',
            recommendedUser: '推薦用戶',
            userRefresh: '用戶刷新',
            chating: '聊天',
            email: '客服',
            customerService: '客服'
          }
        },
        currentLang: {

        }
      }
    },
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2'];
      this.tab[0].name = this.currentLang.chating;
      this.tab[1].name = this.currentLang.email;
      //this.tab[2].name = this.currentLang.customerService;
    },
    activated() {
      //this.getWxConfig(); //加载js
    },
    mounted() {
      //没有token就是代表这人没登录，直接跳转
      if (Cookies.get("token") == "") {
        this.$router.push("login");
      }
      this.getWxConfig(); //加载js
      // this.getUserList(6); //获取随机推荐的6个人
      this.getMessageList(); //获取消息列表

    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },


      checkTab(cur) {
        console.log("==============>切换" + cur)
        this.tabCur = cur;
        //0 聊天  1 邮件 2客服
        if (cur == 1 || cur == 0) {
          this.showUser = true
        } else {
          this.showUser = false
        }
      },
      checkItem(item, cur) {
        //选择对象
        this.$router.push({
          path: '/marry',
          query: {
            'userCode': item.kh
          }
        });

      },
      renderUser() {
        const userBtn = this.userBtn;
        if (userBtn == 1) {
          this.userBtn = 0
        } else {
          this.userBtn = 1
        }
      },
      message(item) {
        //0 聊天  1 邮件 2客服
        console.log("===========>" + this.tabCur)
        switch (this.tabCur) {
          case 0:
            //消息中心
            this.$router.push({
              path: '/chat',
              query: {
                'userCode': item.kh
              }
            });
            break
          case 1:
            //消息中心
            this.$router.push({
              path: '/reply',
              query: {
                'userCode': item.kh
              }
            });
            break
          case 2:
            //客服也要消息中心
            this.$router.push({
              path: '/chat',
              query: {
                'userCode': item.kh
              }
            });
            break
          default:
            //这里是没有找到对应的值处理
            break
        }
      },
      async getMessageList() {
        //短消息列表
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post_2.ashx?action=getMainEmails";
        var param = {
          action: "getMainEmails",
          page: '1',
          msgType: '6',
          user: Cookies.get("kh"),
          mId: "1", //1,全部，2已读
          limit: 100,
          sitenum: '18777777777',
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          var data = result.data;
          //最后一行是合计，不需要显示出来
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              var sData = {
                kh: data[i].post_user_name, //这名字就是卡号
                name: data[i].xm, //这名字
                type: data[i].type,
                conteng: data[i].content,
                img: data[i].headImg,
                unread: data[i].unread,
                fklx: data[i].fklx,
              };
              //if (data[i].content != "") {
              //只要内容不为空，则显示出来，为空，表示是第一次聊天后，对方给的自动回复
              // if (data[i].type == "5") {
              //   that.tabData[0].push(sData);
              // } else {
              //   that.tabData[1].push(sData);
              // }
              that.tabData[0].push(sData);
              that.tabData[1].push(sData);
              //}
            }
          } else {
            Toast(result.msg);
          }
        }
      },

    }
  };
</script>

<style lang="less" scoped>
  .bought {
    &-head {
      &-one {
        padding: 10px 20px;
        position: relative;
      }

      &-left {
        display: flex;
      }

      &-search {
        position: absolute;
        top: 6px;
        right: 20px;
        width: 22px;
      }

      &-item {
        font-size: 12px;
        color: #232323;
        margin-right: 20px;
        cursor: pointer;

        &.cur {
          transform: scale(1.4);
          color: #E64454;
        }
      }

      &-two {
        position: relative;
        margin: 20px 20px 15px;
        font-size: 14px;

        &-title {
          color: #A9A9A9;
        }

        &-right {
          position: absolute;
          top: 0;
          right: 0;
          color: #8690DA;
          cursor: pointer;
        }
      }

      &-user {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &-item {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &-cont {
      border-top: 1px solid #F5F5F5;

      &-item {
        display: flex;
        padding: 15px;
        border-bottom: 1px solid #F5F5F5;
      }

      &-img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }

      &-txtBox {
        margin-left: 12px;
        padding-top: 10px;

        p {
          margin: 0;
          line-height: 1;

          &:last-child {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .bought-cont-txtBox-tip {
    width: 10px;
    margin-right: 4px;
  }

  .refIco {
    position: relative;
    top: 2px;
    left: -4px;
    width: 14px;
  }

  .msg-active {
    position: absolute;
    top: 0px;
    right: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
  }

  .myPhoto {
    position: relative;

  }

  .myPhoto p {
    position: absolute;
    bottom: 0;
    right: 0;
  }
</style>
